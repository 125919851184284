<template>
  <div class="org container">

    <!-- ------------------------------- Modal: Org.Taxes ------------------------------------------ -->

	<CModal title="Tax" color="info" :show.sync="taxModal" size="lg">	
		<div slot="header">
			<h5>Tax Record</h5>
		</div>	  
		<div slot="footer">
			<CButton color="dark" class="mr-3" v-on:click.prevent="serverCall('addUpdateTax')">Save</CButton>
			<CButton color="dark" class="mr-3" v-on:click.prevent="taxModal=false">Close</CButton>
		</div>

		<form>
			<div class="form-group row">
				<label class="col-sm-3 col-form-label font-weight-bold text-right">Name</label>
				<input type="text" class="col-sm-5 form-control" v-model="tax.name">
			</div>

			<div class="form-group row">
				<label class="col-sm-3 col-form-label font-weight-bold text-right">Description</label>
				<input type="text" class="col-sm-8 form-control" v-model="tax.description">
			</div>

			<div class="form-group row">
				<label class="col-sm-3 col-form-label font-weight-bold text-right">Rate (Percentage)</label>
				<input type="number" class="col-sm-5 form-control" v-model="tax.rate">
			</div>

			<div class="form-group row">
				<label class="col-sm-3 col-form-label font-weight-bold text-right">Default</label>
				<div class="col-sm-3 text-left">
				<select class="form-control" v-model="tax.default">
					<option value="Yes">Yes</option>
					<option value="No">No</option>
				</select>
				</div>
			</div>

			<p class="text-danger" align="left">{{taxAlert}}</p>
		</form>

	</CModal>


    <!-- ---------------------------------------  Org Details -------------------------------------------------- -->

  	<div>
		<p class="font-weight-bold" align="center">Organization Details</p>
		<p class="text-danger" align="left">{{alert}}</p>

		<div>
			<label for="loadingMsg" v-if="loading">{{loadingMsg}}</label>
			<scale-loader :loading="loading"></scale-loader>
		</div>
		<p class="text-danger" align="left">{{orgAlert}}</p>

		<div class="card">
			<div class="card-header font-weight-bold" align="right">
				<CButton color="dark" class="mr-3" v-on:click.prevent="serverCall('saveOrgDetails')">Save</CButton>
			</div>

			<div class="card-body">
				<form>
					<div class="form-group row">
						<label class="col-sm-2 col-form-label font-weight-bold text-right">Name</label>
						<input type="text" class="col-sm-3 form-control" v-model="org.name">
					</div>

					<div class="form-group row">
						<label class="col-sm-2 col-form-label font-weight-bold text-right">Full Name</label>
						<input type="text" class="col-sm-6 form-control" v-model="org.fullName">
					</div>

					<div class="form-group row">
						<label class="col-sm-2 col-form-label font-weight-bold text-right">Address 1</label>
						<input type="text" class="col-sm-6 form-control" v-model="org.address1">
					</div>

					<div class="form-group row">
						<label class="col-sm-2 col-form-label font-weight-bold text-right">Address 2</label>
						<input type="text" class="col-sm-6 form-control" v-model="org.address2">
					</div>

					<div class="form-group row">
						<label class="col-sm-2 col-form-label font-weight-bold text-right">Address 3</label>
						<input type="text" class="col-sm-6 form-control" v-model="org.address3">
					</div>

					<div class="form-group row">
						<label class="col-sm-2 col-form-label font-weight-bold text-right">State</label>
						<input type="text" class="col-sm-3 form-control" v-model="org.state">

						<label class="col-sm-2 col-form-label font-weight-bold text-right">Phone</label>
						<input type="text" class="col-sm-3 form-control" v-model="org.phone">
					</div>

					<div class="form-group row">
						<label class="col-sm-2 col-form-label font-weight-bold text-right">GSTN</label>
						<input type="text" class="col-sm-3 form-control" v-model="org.GSTN">

						<label class="col-sm-2 col-form-label font-weight-bold text-right">TimeZone</label>
						<input type="text" class="col-sm-3 form-control" v-model="org.timezone">
					</div>

				</form>
			</div>
		</div>

		<p class="font-weight-bold" align="center">Taxes Applicable</p>
		<p class="text-danger" align="left">{{alert}}</p>

		<vue-good-table ref="taxesTable" 
				:columns="colTaxes" 
				:rows="rowTaxes" 
				@on-row-click="rowChanged" 
				:selectOptions="{
						enabled: true,
						selectOnCheckboxOnly: false, 
				}">
				<div slot="table-actions">
					<CButton color="dark" class="mr-3" v-on:click.prevent="openNewTax()">New</CButton>
				</div>
				<div slot="selected-row-actions">
					<CButton color="dark" v-on:click.prevent="openExistingTax()">Details</CButton>
					<CButton color="dark" class="ml-2" v-on:click.prevent="serverCall('deleteTax')">Delete</CButton>
				</div>
		</vue-good-table>

	</div>

	<main id="main">
	</main>

  </div>
</template>

<script>
import axios from "axios"
import Datepicker from 'vuejs-datepicker'
import ScaleLoader from "@/views/utils/ScaleLoader"
import { firebaseAuth } from '../../firebaseConfig.js';
export default {
	name: 'org',

    components: {
		Datepicker,
        ScaleLoader,
	},
		
	data () {
		return {
			orgId: null,
			orgAlert: '',
			alert: '',
			taxAlert: '',

            loading: false,
            loadingMsg: null,

            orgTimeZone: null,

			org: {},

			tax: {},
			taxModal: false,
			curRowSelected: null,

			rowTaxes: [],
			colTaxes: [
					{
							label: 'Name',
							field: 'name',
							html: false,
					},
					{
							label: 'Description',
							field: 'description',
							html: false,
					},
					{
							label: 'Rate',
							field: 'rate',
							html: false,
					},
					{
							label: 'Default',
							field: 'default',
							html: false,
					},
			],

		}
	},

	computed: {
	},

	mounted(){
		this.orgId = this.$route.params['orgId']
		this.serverCall('getOrgDetails')
	},

	methods: {

        serverCall(name) {
            var self = this

			firebaseAuth.onAuthStateChanged(user => {
				if (user) {
					user.getIdToken(true).then(idToken => {
						switch(name) {
							case "getOrgDetails":
								return self.getOrgDetails(idToken)
								break;

							case "saveOrgDetails":
								self.saveOrgDetails(idToken)
								break;

							case "addUpdateTax":
								self.addUpdateTax(idToken)
								break;

							case "deleteTax":
								self.deleteTax(idToken)
								break;

							default:
								console.log("Unknown function: " + name)
						}
					})
				}
				else {
					self.alert = "Please login first."
				}
			})
		},

        // Fetch Org Details
        getOrgDetails(authToken) {
            var self = this
			self.orgAlert = null

			// Set loading message
			self.loading = true
			self.loadingMsg = "Fetching Org Details..."

            var formData = new FormData()

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/GetOrgDetails/',
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'multipart/form-data',
					},
					data: formData,
				})
				.then(function (response) {
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
					
					if (response.data.err != ''){
						self.orgAlert = response.data.err
					}
					else {
						self.org = response.data.org
						self.rowTaxes = response.data.org.taxes
					}
				})
				.catch(function (error) {
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
					
					self.orgAlert = String(error)
				})

		},

		rowChanged(params){	
			// Current selected Row
			if (this.curRowSelected != null){
				// If new row is not same as previous row, uncheck previous row
				if (this.curRowSelected.originalIndex!=params.row.originalIndex)
					this.curRowSelected.vgtSelected = false
			}
			// New selected row
			this.curRowSelected = params.row
			this.tax= this.curRowSelected
		},

		// Open details for existing tax record
		openExistingTax(){
			this.alert = null
			this.orgAlert = null

            // Check if tax is selected
			if (this.curRowSelected==null){
				this.alert = "Tax record is not selected."
				return
			}

			this.tax = this.curRowSelected

			this.taxModal = true
		},

		// Open details for new tax record
		openNewTax(){
			this.alert = null
			this.orgAlert = null
			this.curRowSelected = null

			// Initialize tax object
			this.tax = {
				name: '',
				description: '',
				rate: 0,
				default: 'Yes',
				vgtSelected: false
			}

			this.taxModal = true
		},

		addUpdateTax(){
			if (this.tax.name.trim()==""){
				this.taxAlert = "Tax name can not be blank."
				return
			}

			// If new record, add
			if (this.curRowSelected==null){
				this.org.taxes.push(this.tax)
			}
			// If existing record, overwrite
			else {
				this.curRowSelected = this.tax
				this.org.taxes[this.curRowSelected['originalIndex']] = this.tax
			}

			this.serverCall('saveOrgDetails')

			this.taxModal = false
		},

		deleteTax(){
            // Check if partner is selected
			if (this.curRowSelected==null){
				this.alert = "Tax record not selected."
				return
			}
			else{
				this.tax = this.curRowSelected
			}

			this.$confirm("Tax Record for " + this.tax.name + " - will be deleted. Are you sure?").then(() => {
				this.org.taxes.splice(this.curRowSelected['originalIndex'], 1)
				this.rowTaxes = this.org.taxes
				this.serverCall('saveOrgDetails')
			})
		},

		// Save Org Details
        saveOrgDetails(authToken) {
            var self = this
            this.orgAlert = ""
			this.alert = ""

			// Set loading message
			self.loading = true
			self.loadingMsg = "Saving Org Details..."
			
			var formData = new FormData()
			self.org.taxes = self.rowTaxes
			formData.append('org', JSON.stringify(self.orgId))

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/SetOrgDetails/',
					data: formData,
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(function (response) {
					// Reset loading message
					self.loading = false
					self.loadingMsg = null

					if (response.data.err != '') {
						self.orgAlert = response.data.err
					}
					else {
						self.orgAlert = "Org Details Saved."
					}
				})
				.catch(function (error) {
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
					self.orgAlert = String(error)
				})

		},


	},

}
</script>

